<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title>{{metaField ? 'Edit' : 'Add'}} Meta Field</v-card-title>

			<v-card-text>
				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>

				<v-select label="Attach this field to" v-model="field.object_type" :items="tables" hide-details dense outlined class="my-3"></v-select>

				<v-row>
					<v-col class="my-3">
						<v-text-field label="Display Name" v-model="field.name" @change="fixMachineName" @keyup="fixMachineName" dense hide-details outlined required></v-text-field>
					</v-col>
					<v-col class="my-3" title="The name developers will use to access this value">
						<v-text-field label="Machine Name" v-model="field.machine_name" @change="fixMachineName" dense hide-details outlined required></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col class="my-3">
						<v-select label="Data Type" v-model="field.type" :items="types" hide-details dense></v-select>
					</v-col>
					<v-col class="my-3" v-show="requiresOptions">
						<v-textarea label="Options (one per line)" v-model="metaOptions" outlined hide-details class="my-3 text-no-wrap" rows="3" auto-grow></v-textarea>
					</v-col>
				</v-row>

				<v-text-field label="Sort Order" v-model="field.order" type="number" dense hide-details outlined required class="my-3"></v-text-field>

				<v-sheet class="preview pa-3" color="grey darken-3 mt-5">
					<label class="text-h6 mr-2">Preview</label>
					<template v-if="!field.type">Please select a Meta Type</template>
					<meta-input v-else v-model="metaValue" :field="field" layout="preview"></meta-input>
				</v-sheet>
			</v-card-text>

			<v-card-actions>
				<v-btn :disabled="saving" @click="modal.trigger('close')" class="ml-auto mr-1">Close</v-btn>
				<v-btn color="primary" :disabled="!canSubmit" @click="submit" :loading="saving">{{metaField ? 'Update' : 'Create'}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import metaInput from "@/components/metaInput";

	export default {
		name: "metaAddEdit",

		props: {
			modal: {
				type: Object,
				required: true,
			},
			metaField: Object,
		},

		components: { metaInput },

		data() {
			return {
				field: {
					id: this.getProp("id"),
					object_type: this.getProp("object_type"),
					type: this.getProp("type"),
					name: this.getProp("name"),
					machine_name: this.getProp("machine_name"),
					options: this.getProp("options"),
					order: this.getProp("order", 0),
				},
				rawMetaOptions: null,
				metaValue: null,
				saving: false,
				modalErrorMessage: false,
				types: [
					{ header: "General" },
					{ value: "boolean", text: "Boolean (true/false, on/off)" },
					{ value: "date", text: "Date" },
					{ value: "email", text: "Email" },
					{ value: "link", text: "Link" },
					{ value: "phone", text: "Phone" },
					{ value: "text", text: "Plain text" },
					{ value: "textarea", text: "Plain Text (long)" },

					{ header: "Number" },
					{ value: "decimal", text: "Number (decimal)" },
					{ value: "integer", text: "Plain Text (long)" },

					{ header: "Multiple" },
					{ value: "checkboxes", text: "Checkboxes" },
					{ value: "radio", text: "Radio" },
					{ value: "dropdown", text: "Dropdown" },

					{ header: "Reference" },
					{ value: "person", text: "Person" },
					{ value: "client", text: "Client" },
					{ value: "project", text: "Project" },
				],
			};
		},

		asyncComputed: {
			allThePeople() {
				this.$xhrRequest
					.send("get", "/api/contact/list", {
						fields:
							"id,first_name,last_name,client_links,project_links",
					})
					.then((people) => {
						this.$db.addModels("person", people, "people");
						return people;
					});
			},
		},

		computed: {
			tables() {
				return [
					{
						value: "Client",
						text: "Clients",
					},
					{
						value: "Person",
						text: "People",
					},
					{
						value: "Project",
						text: "Projects",
					},
					{
						value: "Task",
						text: "Tasks",
					},
				];
			},
			requiresOptions() {
				return (
					this.field.type &&
					"checkboxes radio dropdown".indexOf(this.field.type) >= 0
				);
			},
			canSubmit() {
				if (!this.field.name || !this.field.type) {
					return false;
				}
				if (
					this.requiresOptions &&
					(!this.field.options || !this.field.options.length)
				) {
					return false;
				}
				return true;
			},
			metaOptions: {
				get() {
					return this.rawMetaOptions;
				},
				set(val) {
					let orig = this.rawMetaOptions;
					let newVal = val
						.split("\n")
						.map(i => {
							return i
								.replace(/[ _-]+/g, '_')
								.replace(/^[^a-z]+|[^a-z0-9_]/gi, '');
						})
						.join("\n");

					if (newVal == orig) {
						this.rawMetaOptions = (new Array(orig.length + 2)).join('~');
						this.$nextTick(() => {
							this.rawMetaOptions = newVal;
						});
					}
					else {
						this.rawMetaOptions = newVal;
					}
				}
			},
			metaOptionsArray() {
				if (!this.requiresOptions) {
					return null;
				}

				let result = this.optionsStringToArray(this.metaOptions)
					.filter(i => i);

				return result.filter((v, i) => result.indexOf(v) == i)
			},
		},

		methods: {
			optionsStringToArray(str) {
				return str
					.split("\n")
					.map((item) => item.trim());
			},
			getProp(key, defaultValue = null) {
				if (
					this.metaField &&
					key in this.metaField &&
					this.metaField[key]
				) {
					return this.metaField[key];
				}
				return defaultValue;
			},
			warnIfDataLoss() {
				if (this.field.type !== this.getProp("type")) {
					// todo?
				}
			},
			fixMachineName(e) {
				let name = e.target.value;

				name = name
					.toLowerCase()
					.replace(/[^a-z0-9]+/g, "_")
					.replace(/^_|_$/g, "");
				if (name.indexOf("meta_") !== 0) {
					name = "meta_" + name;
				}

				this.field.machine_name = name;
			},
			submit() {
				let method = "post";
				let url = "/api/meta";
				if (this.field.id) {
					method = "put";
					url += "/" + this.field.id;
				}
				this.warnIfDataLoss();
				this.saving = true;
				this.$xhrRequest.send(method, url, this.field).then(
					(item) => {
						this.saving = false;
						this.$db.addModels("meta", item, "meta");
						this.modal.trigger("save close", item);
						this.$snotify.success(
							method === "put"
								? "Updated meta field"
								: `Created new meta field`
						);
					},
					(xhr) => {
						this.saving = false;

						let errors = this.$insight.helpers.getRequestErrors(xhr);
						errors = this.$insight.helpers.flattenRequestErrors(errors);

						this.modalErrorMessage = errors.join("<br>");
					}
				);
			},
		},
		watch: {
			metaField: {
				immediate: true,
				handler(to) {
					this.metaOptions = to
						? (to.options || []).join("\n")
						: '';
				}
			},
			metaOptionsArray: {
				immediate: true,
				handler(to) {
					this.field.options = to;
				}
			}
		},
	};
</script>